<template>
    <div class="action-contact-done">
        <div class="title" v-html="$translate('ACTION_CONTACT_DONE_TITLE')" />
        <div class="body">
            <div v-html="body" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionContactDone',
    props: ['message'],
    computed: {
        contactUser() {
            return this.$mustParse(this.message.content).name || this.$mustParse(this.message.content).nickname
        },
        body() {
            return this.$translate('ACTION_CONTACT_DONE_BODY').replace(/%s/, this.contactUser)
        },
    },
}
</script>
